@import url(http://fonts.googleapis.com/css?family=Fjalla+One);
@import url(http://fonts.googleapis.com/css?family=Gudea);

.footer1 {
  /* background: #fff url("../images/footer/footer-bg.png") repeat scroll left top;*/
  /*padding-top: 40px;*/
  padding-right: 0;
  padding-bottom: 20px;
  padding-left: 0; /*	border-top-width: 4px;
	border-top-style: solid;
	border-top-color: #003;*/
}

.title-widget {
  color: white;
  font-size: 20px;
  font-weight: 300;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 25px;
  margin-left: 0;
  padding-left: 28px;
}

.title-widget::before {
  background-color: #84c126;
  content: "";
  height: 22px;
  left: 0px;
  position: absolute;
  top: -2px;
  width: 5px;
}

.title-widget li a {
  color: white;
}

.widget_nav_menu ul {
  list-style: outside none none;
  padding-left: 0;
}

.widget_archive ul li {
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 3px;
}

.widget_nav_menu ul li {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
  /*text-transform: uppercase;*/
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 7px;
  padding-bottom: 7px;
  width: 95%;
}

.title-median {
  color: white;
  font-size: 20px;
  line-height: 20px;
  margin: 0 0 15px;
  /*text-transform: uppercase;*/
  font-family: "Open Sans", sans-serif;
}

.footerp p {
  font-family: "Gudea", sans-serif;
}

/* #social:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}
#social {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
} */
/* 
    Only Needed in Multi-Coloured Variation 
                                               */
.social-fb:hover {
  color: #3b5998;
}
.social-tw:hover {
  color: #4099ff;
}
.social-gp:hover {
  color: #d34836;
}
.social-em:hover {
  color: #f39c12;
}
.nomargin {
  margin: 0px;
  padding: 0px;
}

.footer-bottom {
  background-color: #15224f;
  min-height: 30px;
  width: 100%;
}
.copyright {
  color: #fff;
  line-height: 30px;
  min-height: 30px;
  padding: 7px 0;
}
.design {
  color: #fff;
  line-height: 30px;
  min-height: 30px;
  padding: 7px 0;
  text-align: right;
}
.design a {
  color: #fff;
}
